/** @jsx jsx */
import * as React from "react"
import { Global } from "@emotion/core"
import { Box, Container, jsx, useColorMode } from "theme-ui"
import "typeface-ibm-plex-sans"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"
import CodeStyles from "../styles/code"
import SkipNavLink from "./skip-nav"
import customStyles from '../styles/custom';

type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({ children, className = `` }: LayoutProps) => {
  const [colorMode] = useColorMode()

  return (
      <React.Fragment>
        <Global
            styles={(theme) => ({
              "*": {
                boxSizing: `inherit`,
              },
              html: {
                WebkitTextSizeAdjust: `100%`,
              },
              img: {
                borderStyle: `none`,
              },
              pre: {
                fontFamily: `monospace`,
                fontSize: `1em`,
              },
              "[hidden]": {
                display: `none`,
              },
              "::selection": {
                backgroundColor: theme.colors.text,
                color: theme.colors.background,
              },
              a: {
                transition: `all 0.3s ease-in-out`,
                color: `text`,
              },
              ...customStyles
            })}
        />
        <SEO />
        <SkipNavLink>Skip to content</SkipNavLink>
        <Container className={colorMode === 'dark' ? 'theme-dark' : 'theme-light'}>
          <Header />
          <Box id="skip-nav" sx={{...CodeStyles}} className={className}>
            <main className={'main'}>
              {children}
            </main>
          </Box>
          <Footer />
        </Container>
      </React.Fragment>
  );
}

export default Layout
