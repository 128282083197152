// solves two stacked different size svgs problem
export default {
  'source[srcset*=".svg"] ~ img': {
    display: 'none'
  },
  'main p': {
    'max-width': '100vw',
    'overflow': 'auto'
  },
  '.theme-dark .gatsby-resp-image-background-image': {
    'background-color': 'white'
  }
}
